/* eslint-disable */

import {
  Stack,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  Container,
} from "@mui/material";
import ProcessContent from "./ProcessContent";
import CompanySetup from "assets/icons/ProcessImageFolder/CompanySetup.svg";
import DemoDay from "assets/icons/ProcessImageFolder/DemoDay.svg";
import PreSeed from "assets/icons/ProcessImageFolder/PreSeed.svg";
import StartupStudio from "assets/icons/ProcessImageFolder/StartupStudio.svg";
import TestAndRedeployment from "assets/icons/ProcessImageFolder/TestAndRedeployment.svg";
import FurtherDevelopment from "assets/icons/ProcessImageFolder/FurtherDevelopment.svg";
import Arrow from "assets/illustrations/ourService/Arrow.svg";
import { useTheme } from "@mui/material/styles";

function Process() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container>
      <Stack
        sx={{
          backgroundColor:
            "linear-gradient(180deg, #F1F8FE 0%, rgba(255, 255, 255, 0) 7.18%",
        }}
        mt={10}
        textAlign="center"
      >
        <Grid item mb={10}>
          <Stack textAlign="center" alignItems="center" justifyContent="center">
            <img
              src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665849440/website-assets/images/OurService/processHeader_azyh6m.svg"
              alt="the process"
            />
            <Box width={isMobile ? "312px" : "638px"} mt={5}>
              <Typography
                fontWeight="400"
                fontSize={isMobile ? "16px" : "18px"}
                height="90px"
                color="#667085"
                maxWidth={isMobile ? "312px" : "600px"}
              >
                As founders with experience building innovative technologies, we
                co-build with early-stage founders throughout their journey by
                offering the necessary support and services.
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid
          item
          container
          position="relative"
          justifyContent={isMobile ? "center" : "space-around"}
        >
          {!isMobile && (
            <Box
              position="absolute"
              zIndex={1}
              left="0"
              right="0"
              margin="auto"
              top="20%"
            >
              <img src={Arrow} alt="" width="20%" />
            </Box>
          )}
          {!isMobile && (
            <Box
              position="absolute"
              zIndex={1}
              left="0"
              right="0"
              margin="auto"
              top="45%"
            >
              <img src={Arrow} alt="" width="20%" />
            </Box>
          )}
          {!isMobile && (
            <Box
              position="absolute"
              zIndex={1}
              left="0"
              right="0"
              margin="auto"
              top="70%"
            >
              <img src={Arrow} alt="" width="20%" />
            </Box>
          )}
          <Grid item>
            <ProcessContent
              heading="Startup Studio"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665849737/website-assets/images/OurService/startupImg_q9kped.svg"
              text="3-Months workshop to develop their product ideas"
            />
            <ProcessContent
              heading="Build"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823252/website-assets/images/OurService/processBuild_kyyay6.svg"
              text="Build, test and re-deploy product, provide technical, admin, mentorship and networking"
            />
            <ProcessContent
              heading="Test and Redeployment"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850209/website-assets/images/OurService/test_eyvabe.svg"
              text="Product is tested and improved."
            />
            <ProcessContent
              heading="Investment Readiness"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850193/website-assets/images/OurService/investment_yd7z0g.svg"
              text="Prepare companies for follow-on investments"
            />
          </Grid>
          <Grid item pt={!isMobile && 10}>
            <ProcessContent
              heading="Company Setup"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850164/website-assets/images/OurService/company_ybwrfr.svg"
              text="Register company, set-up bank account, founders agreement and milestones"
            />

            <ProcessContent
              heading="Pre-Seed"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850201/website-assets/images/OurService/preseed_tvqml3.svg"
              text="Funds of up to $25k is provided to founders."
            />
            <ProcessContent
              heading="Demo day"
              icon="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850171/website-assets/images/OurService/demoday_jbjj1l.svg"
              text="To showcase product to investors, investment committee and build traction."
            />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}
export default Process;
