/* eslint-disable */
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useState } from "react";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const InDirectImpacts = [
  {
    title: "Exploration & experimentation",
    caption:
      "Sustained economic growth through exploration and experimentation that increase overall chances of success",
    color: "#009FF5",
  },
  {
    title: "Collective know-how",
    caption: "Increasing know-how in business management and technology",
    color: "#FF7E1D",
  },
  {
    title: "Opportunities in struggle",
    caption:
      "Encouraging and welcoming thinkers and tinkerers who are ambitiously optimistic about finding opportunities in struggle",
    color: "#343256",
  },
];

function InDirectImpact() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const contents = InDirectImpacts;
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = contents.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box paddingY={isMobile ? "10px" : 10} sx={{ backgroundColor: "#F6F6F6" }}>
      <Container>
        <Grid
          container
          my={isMobile && 5}
          justifyContent="center"
          alignItems="center"
          dirction="column"
          gap={8}
        >
          <Grid item textAlign="center" maxWidth={isMobile ? "320px" : "100%"}>
            <Typography
              lineHeight="40px"
              letterSpacing="-4"
              fontWeight={700}
              color="#101828"
              fontSize={isMobile ? "24px" : "32px"}
            >
              Indirect Impact - Unleashing a culture of innovation
            </Typography>
          </Grid>
          <Grid item>
            {isMobile ? (
              <Grid sx={{ maxWidth: "90vw" }} padding={isMobile ? "10px" : 10}>
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {contents.map((value, index) => (
                    <div key={index}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Grid
                          container
                          key={index}
                          sx={{
                            borderTop: `5px solid ${value.color}`,
                          }}
                          justifyContent="left"
                          item
                          lg={4}
                          xl={4}
                          md={4}
                          xs={12}
                          sm={12}
                          p={isMobile ? "5px" : "5px"}
                          alignItems="left"
                          textAlign="left"
                        >
                          <Typography
                            mt="34px"
                            fontWeight={600}
                            fontSize={20}
                            color="#101828"
                          >
                            {value.title}
                          </Typography>
                          <Typography
                            mt="20px"
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="21px"
                            color="#667085"
                          >
                            {value.caption}
                          </Typography>
                        </Grid>
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
              </Grid>
            ) : (
              <Grid
                container
                spacing={isMobile ? "10px" : 2}
                alignItems="top"
                justifyContent={isMobile ? "center" : "space-between"}
              >
                {InDirectImpacts.map((value, index) => (
                  <Grid
                    container
                    key={index}
                    sx={{
                      borderTop: `5px solid ${value.color}`,
                    }}
                    justifyContent={isMobile ? "center" : "space-between"}
                    item
                    lg={4}
                    xl={4}
                    md={4}
                    xs={12}
                    sm={12}
                    p={isMobile ? "5px" : "5px"}
                    alignItems="left"
                    textAlign="left"
                  >
                    <Typography
                      mt="34px"
                      fontWeight={600}
                      fontSize={20}
                      color="#101828"
                    >
                      {value.title}
                    </Typography>
                    <Typography
                      mt="20px"
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="21px"
                      color="#667085"
                    >
                      {value.caption}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default InDirectImpact;
