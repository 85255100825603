/* eslint-disable */
import Hero from "./components/Hero";
import Process from "./components/Process";
import ServicesProvided from "./components/ServicesProvided";
import ServicesLnR from "./components/ServicesLnR";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

function Services() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const nexusVideo = (
    <iframe
      width={isMobile ? "100%" : "500px"}
      height={isMobile ? "100%" : "400px"}
      src="https://www.youtube.com/embed/tt70EfZTnxI?loop=1&playlist=tt70EfZTnxI"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );

  const services = [
    {
      title: "GrowthLab",
      image:
        "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823249/website-assets/images/OurService/growthLab_xu97hk.svg",
      description: `We focus on co-building and developing startups swiftly and efficiently by accepting founders who have illustrated commitment, vision, and skill set to build and run technology-enabled businesses.`,
      description1: `We offer early-stage business support to enable companies to launch (typically within 6 months) a viable product capable of reaching market fit, scaling, and ultimately achieving a profitable exit.`,
      color: "rgba(255, 65, 51, 0.03)",
      button: false,
      position: "right",
    },
    {
      title: "The Venture Fund",
      image:
        "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742038/website-assets/images/Home_Images/plant_csjatf.svg",
      description: `The fund is set up to deploy pre-seed stage capital to startups in our portfolio and address early-stage funding for startups in Africa. It enables founders to build quickly and engage external support services when needed.`,
      color: "#FFF",
      button: false,
      position: "left",
    },
    {
      title: "Nexus Training",
      image: nexusVideo,
      description: ` While building their products, founders participate in the Nexus program, a 6-month business management course delivered by Henley Business School and Lagos Business School, aimed at providing a “mini-MBA” and mentorship for entrepreneurs.`,
      color: "rgba(255, 167, 0, 0.01)",
      button: false,
      position: "right",
    },
    {
      title: "Startup Studio",
      image:
        "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823256/website-assets/images/OurService/startUp_ao6sw7.svg",
      description: `The Studio serves as a 3-month immersive workshop program which gives entrepreneurs the opportunity to develop their ideas, gather the necessary skills to scale and develop their Minimum Viable Product (MVP).`,
      description1: `We combine design thinking, lean startup, and agile methodologies to identify real customer problems and develop the right products based on a sound business model. We help the founders structure all necessary agreements and administrative tasks like accounts set-up and registration of their companies.`,
      color: "#fff",
      button: false,
      position: "left",
    },
    {
      title: "Open Innovation",
      image:
        "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823248/website-assets/images/OurService/openInnovation_bwtclz.svg",
      description: `Semicolon Ventures operates a corporate innovation model that enables companies to engage early with startups or have teams work on business challenges of interest without significant upfront costs.`,
      description1: `We partner with corporate organizations to develop innovative product solutions for our corporate partners through research, product development, and project implementation.`,
      color: "rgba(27, 138, 237, 0.03)",
      button: false,
      position: "right",
    },
  ];
  return (
    <>
      <Hero />
      {services.map((service, index) => (
        <Grid sx={{ backgroundColor: service.color }} key={index}>
          <ServicesLnR
            title={service.title}
            color={service.color}
            position={service.position}
            description={service.description}
            description1={service.description1}
            button={service.button}
            image={service.image}
          />
        </Grid>
      ))}
      <ServicesProvided />
      <Process />
    </>
  );
}
export default Services;
