/* eslint-disable */
import {
  Grid,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StartupCard from "./StartupCard";
import SwipeableMobileCarousel from "./mobileCarousel";
import content from "./content";

function Hero() {
  const startupdata = content;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid
        sx={{
          backgroundColor:
            "linear-gradient(180deg, #F1F8FE 0%, rgba(255, 255, 255, 0) 7.18%",
        }}
        container
        mt={isMobile ? 10 : 20}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        minHeight="80vh"
        direction="column"
      >
        <Grid item mb={10}>
          <Stack textAlign="center" alignItems="center" justifyContent="center">
            <Typography
              fontSize="50px"
              fontWeight="900"
              fontFamily="IBM Plex Sans"
            >
              The Founders
            </Typography>
            <Typography
              mt={5}
              fontWeight="400"
              maxWidth={isMobile ? "80vw" : "70%"}
              fontSize={isMobile ? "16px" : "18px"}
              height="90px"
              color="#667085"
            >
              We invest in ambitious entrepreneurs, building companies that
              solve local and global problems in innovative ways to increase
              economic inclusion and employment opportunities that result in
              economic growth.
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          {isMobile ? (
            <SwipeableMobileCarousel />
          ) : (
            <Stack
              direction={isMobile ? "column" : "row"}
              // px={isMobile ? '20px' : 20}
              spacing={10}
              fontSize="16px"
              alignItems={isMobile ? "center" : "top"}
              justifyContent="center"
            >
              {startupdata.map((startup, index) => (
                <StartupCard
                  key={index}
                  title={startup.title}
                  description={startup.description}
                  image={startup.image}
                />
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
