/* eslint-disable */
import {
  Typography,
  Stack,
  Box,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import content from "./content";
import SwipeableMobileCarousel from "./mobileCarousel";
import ActivityCard from "./WhatWeDoCard";

function WhatWeDo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const activity = content;

  return (
    <Container>
      <Stack
        direction="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        mt={!isMobile && "80px"}
      >
        <Typography
          lineHeight="40px"
          fontWeight={700}
          color="#101828"
          fontSize={32}
        >
          What we do
        </Typography>
        <Box mt="13px" mb={isMobile ? "32px" : "47px"}>
          <img
            src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742042/website-assets/images/Home_Images/yellowDash_czrtun.svg"
            alt="underline"
          />
        </Box>
        {isMobile ? (
          <SwipeableMobileCarousel />
        ) : (
          <Stack gap={10}>
            <Stack
              direction="row"
              gap={10}
              alignItems="center"
              justifyContent="center"
            >
              <ActivityCard
                title={activity[0].title}
                description={activity[0].description}
                image="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/build_wkvp1b.svg"
              />
              <ActivityCard
                title={activity[1].title}
                description={activity[1].description}
                image="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/launch_b8rzhy.svg"
              />
              <ActivityCard
                title={activity[2].title}
                description={activity[2].description}
                image="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/funding_wiiv6i.svg"
              />
            </Stack>
            <Stack
              direction="row"
              gap={10}
              alignItems="center"
              justifyContent="center"
            >
              <ActivityCard
                title={activity[3].title}
                description={activity[3].description}
                image="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850529/website-assets/logo/co_inno_af6n4h.svg"
              />
              <ActivityCard
                title={activity[4].title}
                description={activity[4].description}
                image="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/nuture_team_p7ghyg.svg"
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

export default WhatWeDo;
