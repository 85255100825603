/* eslint-disable */
const content = [
  {
    title: "Onhova",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823259/website-assets/images/OurService/founderOnhova_uqetqw.svg",
    description: `Onhova is a DIY digital solution that enables businesses to set up an e-commerce site with their smartphones in 30 seconds and manage inventories, orders, customers, etc. Business owners can also access services like logistics and marketing tools needed to grow.`,
  },
  {
    title: "Condueet",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823270/website-assets/images/OurService/founderCondueet_vmdxcs.svg",
    description: `Condueet is building financial integration infrastructure, implementing channels available through the advent of open
            banking to enable interaction and visibility across the financial services industry. `,
  },
  {
    title: "Goodtime",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823261/website-assets/images/OurService/founderGoodtime_xvshq0.svg",
    description: `GoodTime is a community curated for users and businesses by match-making them based on their recreational interests and activities.`,
  },
];

export default content;
