/* eslint-disable */

import nexusTraining from "../../../../assets/images/aboutUs/nexusTraining.svg";
import startupClinic from "../../../../assets/images/aboutUs/startupStudio.svg";

const content = [
  {
    img: nexusTraining,
    heading: "Nexus Training",
    description:
      "While building their products, founders participate in the Nexus program, a 6-month business management course delivered by Henley Business School and Lagos Business School, aimed at providing a “mini-MBA” and mentorship for entrepreneurs.",

    to: "/nexus",
  },
  {
    img: startupClinic,
    heading: "Startup Studio",
    description:
      "The Studio is a 3-month immersive workshop program that allows entrepreneurs to develop their ideas, gather the necessary skills to scale, and develop their Minimum Viable Product (MVP).",
    // button2: "Learn more",
    to: "/our-services",
  },
];
export default content;
