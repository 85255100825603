/* eslint-disable */
import {
  Box,
  Button,
  Divider,
  Grid,
  useMediaQuery,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import Logo from "../../assets/logos/logo.svg";
import Instagram from "assets/icons/Instagram.svg";
import Facebook from "assets/icons/Facebook.svg";
import Twitter from "assets/icons/Twitter2.svg";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import "../header/Header.css";

const content = [
  {
    title: "Impact",
    page: "our-impact",
    path: "/our-impact",
  },
  {
    title: "Venture Fund",
    page: "venture-fund",
    path: "/venture-fund",
  },
  {
    title: "Services",
    page: 'our-services"',
    path: '/our-services"',
  },
  {
    title: "Portfolio",
    page: "portfolio",
    path: "/portfolio",
  },

  {
    title: "Nexus",
    page: "nexus",
    path: "/nexus",
  },

  {
    title: "About Us",
    page: "about",
    path: "/about",
  },
];

const content2 = [
  {
    name: "Follow Us",
    icon1: Facebook,
    icon2: Twitter,
    icon3: Instagram,
  },
  {
    name: "Address",
    text: "371 Borno way, Alagomeji, Lagos.",
  },
];

function FooterBody() {
  const className = "headerlink-title";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      sx={{
        backgroundColor: "#F9FAFE",
      }}
    >
      <Container>
        <Grid
          container
          minHeight="533px"
          sx={{
            backgroundColor: "#F9FAFE",
            pt: "68px",
          }}
          alignItems={isMobile ? "center" : "space-between"}
          justifyContent={isMobile ? "center" : "space-between"}
          direction="column"
        >
          <Grid item>
            <Grid
              container
              justifyContent={isMobile ? "center" : "space-between"}
              alignItems="center"
            >
              <Grid item order={isMobile ? 2 : 1} lg={7}>
                <Grid container mt={isMobile && "90px"}>
                  <Grid
                    item
                    textAlign={isMobile ? "center" : ""}
                    justifyContent={isMobile ? "center" : "space-between"}
                  >
                    <img src={Logo} alt="learnspace logo" />
                    <Typography
                      fontWeight={400}
                      fontSize={isMobile ? "14px" : "18px"}
                      lineHeight="30px"
                      color="#5C6D79"
                      mt="25px"
                      maxWidth={isMobile ? "280px" : "400px"}
                    >
                      A venture builder of innovative technologies that solve
                      local and global problems
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  lg={11}
                  mt="40px"
                  direction="row"
                  spacing={isMobile ? "48px" : "5px"}
                  alignItems={isMobile ? "center" : "left"}
                  justifyContent={isMobile ? "center" : "space-between"}
                  paddingX={isMobile && "10px"}
                  maxWidth={isMobile ? "280px" : "90%"}
                >
                  {content.map((nav, index) => (
                    <Grid
                      key={index}
                      item
                      lg={2}
                      xl={2}
                      xs={6}
                      sm={6}
                      md={6}
                      mb={isMobile && 5}
                    >
                      <Grid container width="100px">
                        <Link to={`/${nav.page}`} className={className}>
                          <Typography
                            fontSize="14px"
                            fontWeight="700"
                            textAlign="left"
                          >
                            {nav.title}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid
                  container
                  mt="5%"
                  direction="row"
                  gap={isMobile ? "48px" : "16px"}
                  alignItems={isMobile ? "top" : "left"}
                  justifyContent={isMobile ? "space-between" : "left"}
                  paddingX={isMobile && "10px"}
                  maxWidth={isMobile ? "280px" : "400px"}
                >
                  {content2.map((nav, index) => (
                    <Grid key={index} item>
                      <Typography
                        sx={{
                          fontWeight: isMobile ? 700 : 700,
                          fontSize: "15px",
                          lineHeight: "21px",
                          color: "#333333",
                        }}
                      >
                        {nav.name}
                      </Typography>
                      <Box mt="7px" width="150px">
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#5C6D79",
                          }}
                        >
                          {nav.text}
                        </Typography>
                      </Box>
                      {nav.icon1 && (
                        <Box
                          display="flex"
                          flexDirecton="row"
                          justify="space-between"
                          paddingRight="20px"
                        >
                          <img src={nav.icon1} alt="fb" />
                          <img src={nav.icon2} alt="fb" />
                          <img src={nav.icon3} alt="fb" />
                        </Box>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item order={isMobile ? 1 : 2} lg={5}>
                <Grid item mb="22px">
                  <Typography fontSize="20px" fontWeight={700} color="#333333">
                    Be the first to hear from us
                  </Typography>
                </Grid>

                <Grid item lg={12}>
                  <form>
                    <Grid container gap="5%">
                      <Grid item lg={12} xl={12} xs={12} sm={12}>
                        <Typography
                          fontSize="16px"
                          fontWeight="600"
                          color="#344054"
                        >
                          Name
                        </Typography>
                        <TextField
                          fullWidth
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                          }}
                          type="text"
                          placeholder="Type your Name"
                        />
                      </Grid>
                      <Grid item lg={12} xl={12} xs={12} sm={12}>
                        <Typography
                          fontSize="16px"
                          fontWeight="600"
                          color="#344054"
                          marginTop="20px"
                        >
                          Email Address
                        </Typography>
                        <TextField
                          fullWidth
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                          }}
                          type="email"
                          placeholder="Type your Email Address"
                        />
                      </Grid>
                      <Grid item lg={12} xl={12} xs={12} sm={12}>
                        <Button
                          variant="contained"
                          width={isMobile ? "100%" : "100%"}
                          fullWidth
                          sx={{
                            backgroundColor: "#1B8AED",
                            mt: "24px",
                            padding: "15px 20px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "#fff",
                            borderRadius: "7px",
                            height: `${isMobile ? "45px" : "48px"}`,
                            textTransform: "none",
                            "&:hover": { backgroundColor: "#1B8AED" },
                          }}
                          disableElevation
                        >
                          <Typography
                            fontSize="16px"
                            color="#FFFFFF"
                            lineHeight="24px"
                            fontWeight={700}
                          >
                            Subscribe
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mt="40px">
            {!isMobile && <Divider variant="center" />}
            <Typography
              fontWeight="400"
              color="#646464"
              fontSize="14px"
              lineHeight="14px"
              py="32px"
            >
              {`${new Date().getFullYear()} Semicolon Ventures. All rights reserved.`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
export default FooterBody;
