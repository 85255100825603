/* eslint-disable */
import {
  Box,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const industrySegments = [
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823437/website-assets/images/Venture/industryFitech_hmo6ud.svg",
    title: "FinTech",
    caption:
      "Technology used to help companies and consumers manage their finances",
    color: "rgba(27, 138, 237, 1)",
    backgroundColor: "rgba(27, 138, 237, 0.02)",
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823438/website-assets/images/Venture/industryMobility_infuk0.svg",
    title: "Mobility",
    caption:
      "Technology applied to mobility increases efficiency and improves customer experience",
    color: "rgba(51, 51, 51, 1)",
    backgroundColor: "rgba(51, 51, 51, 0.05)",
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823439/website-assets/images/Venture/industryTourism_rhp2ro.svg",
    title: "Tourism",
    caption:
      "Technology applied to support and improve how people explore and move from place to place",
    color: "rgba(237, 27, 65, 1)",
    backgroundColor: "rgba(237, 27, 65, 0.04)",
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823437/website-assets/images/Venture/industryCivic_r3txnh.svg",
    title: "CivicTech",
    caption:
      "Technology used to educate and empower citizens of their rights and role in nation building",
    color: "rgba(192, 146, 35, 1)",
    backgroundColor: "rgba(192, 146, 35, 0.04)",
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823437/website-assets/images/Venture/industryAgric_tony20.svg",
    title: "AgricTech",
    caption:
      "Technology applied to agriculture to increase yield, effiiciency and profitability of agro produce",
    color: "rgba(255, 142, 0, 1) ",
    backgroundColor: "rgba(255, 142, 0, 0.02)",
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823437/website-assets/images/Venture/industryHealth_hv3pgc.svg",
    title: "Digital Health",
    caption:
      "Technology applied to improve, support and promote better and healthy living",
    color: "rgba(21, 148, 0, 1)",
    backgroundColor: "rgba(21, 148, 0, 0.04)",
  },
];

function PortfolioConstruction() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container>
      <Grid
        container
        my={isMobile && 20}
        paddingY={isMobile ? "10px" : 10}
        justifyContent="center"
        alignItems="center"
        spacing={4}
        backgroundColor="#F6F6F6"
      >
        <Grid item textAlign="center">
          <Stack
            direction="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
          >
            <Typography
              lineHeight="40px"
              letterSpacing="-4"
              fontWeight={700}
              color="#101828"
              fontSize="32px"
            >
              Portfolio Construction
            </Typography>
            <Box>
              <img
                src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742042/website-assets/images/Home_Images/yellowDash_czrtun.svg"
                alt="underline"
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={isMobile ? "10px" : 10}
            justifyContent="center"
            alignItems="top"
          >
            {industrySegments.map((value, index) => (
              <Grid key={index} item lg={4} xl={4} md={4} xs={12} sm={12}>
                <Stack
                  backgroundColor={value?.backgroundColor}
                  borderRadius="12px"
                  // width="195px"
                  height="250px"
                  justifyContent="flex-start"
                  py="30px"
                  px={isMobile ? "5px" : "20px"}
                  alignItems="center"
                  textAlign="center"
                >
                  <img src={value?.icon} alt="" width="70px" height="70px" />
                  {value.title && (
                    <Typography
                      mt="34px"
                      fontWeight={600}
                      fontSize={20}
                      color={value.color}
                    >
                      {value?.title}
                    </Typography>
                  )}
                  <Typography
                    mt="20px"
                    fontWeight={400}
                    fontSize="16px"
                    lineHeight="21px"
                    color="#101828"
                  >
                    {value?.caption}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PortfolioConstruction;
