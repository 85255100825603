/* eslint-disable */

import { Container, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function NexusVideo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid backgroundColor="#F6F6F6" width="100vw">
      <Grid width="70%" margin="auto">
        <iframe
          width="100%"
          height={isMobile ? "250px" : "615px"}
          src="https://www.youtube.com/embed/tt70EfZTnxI?autoplay=1&mute=1&loop=1&playlist=tt70EfZTnxI"
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Grid>
    </Grid>
  );
}
