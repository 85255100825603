/* eslint-disable */

import { Grid, Typography, useMediaQuery, Box } from "@mui/material";
import CitiesImage from "assets/illustrations/cities.svg";
import { useTheme } from "@mui/material/styles";

function WhoWeAre() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      minHeight={isMobile ? "50vh" : "70vh"}
      sx={{
        backgroundImage: `url(${!isMobile && CitiesImage})`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
      justifyContent="center"
      alignItems="center"
      pb={20}
    >
      <Grid item lg={12}>
        <Box
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="405px"
          position="relative"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{ zIndex: 3, justifySelf: "center", alignSelf: "center" }}
            position="absolute"
            top="12%"
          >
            <img
              src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665849936/website-assets/logo/who_we_are_j4muij.svg"
              alt="launch"
            />
          </Box>
          <Typography
            textAlign="center"
            fontWeight="700"
            fontSize="32px"
            letterSpacing="-4%"
            lineHeight="40px"
            color="#1B8AED"
          >
            Who we are
          </Typography>
          <Typography
            textAlign="center"
            fontWeight="400"
            fontSize={`${isMobile ? 18 : 15}px`}
            lineHeight="19.5px"
            color="#000"
            maxWidth={`${isMobile ? 600 : 465}px`}
            mt="64px"
          >
            Semicolon Ventures is a venture building firm focused on developing
            innovative technology solutions with our entrepreneurs and corporate
            partners.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default WhoWeAre;
