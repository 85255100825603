/* eslint-disable */
import AboutUsIdea from "assets/images/aboutUs/about-hero-image.svg";
import AboutUsIdea1 from "assets/images/aboutUs/about-hero-mobile.svg";
import LFTpattern from "assets/illustrations/line-pattern.svg";
import { useTheme } from "@mui/material/styles";
import { Grid, Box, Typography, useMediaQuery, Container } from "@mui/material";

function Hero() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid backgroundColor="#F4F4F4">
      <Container>
        <Grid
          container
          position="relative"
          height="100vh"
          textAlign="center"
          alignItems="center"
          direction={isMobile ? "" : "row"}
          justifyContent={isMobile ? "center" : "space-between"}
        >
          <Grid
            item
            mt={isMobile ? "5%" : "100px"}
            mb={isMobile ? "10%" : ""}
            textAlign={isMobile ? "center" : "left"}
            order={isMobile ? 2 : 1}
          >
            <Typography
              fontWeight={isMobile ? 600 : 700}
              fontSize={isMobile ? 32 : 60}
              lineHeight={isMobile ? "" : "78px"}
              color="#101828"
              textAlign={isMobile ? "center" : "left"}
              letterSpacing={isMobile ? "" : "-4px"}
              width={isMobile ? "265px" : "350px"}
            >
              Semicolon for
            </Typography>
            <Typography
              fontWeight={isMobile ? 600 : 700}
              fontSize={isMobile ? 32 : 60}
              lineHeight={isMobile ? "" : "78px"}
              textAlign={isMobile ? "center" : "left"}
              letterSpacing={isMobile ? "" : "-4px"}
              width={isMobile ? "265px" : ""}
              color="#1B8AED"
            >
              Innovators
            </Typography>
          </Grid>
          <Grid
            mt={isMobile ? "10%" : ""}
            display={!isMobile && "flex"}
            justifyContent={!isMobile && "right"}
            item
            order={isMobile ? 1 : 2}
          >
            <img
              src={
                isMobile
                  ? AboutUsIdea1
                  : "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665747419/website-assets/images/about-us/home_oluucd.svg"
              }
              alt=""
              width={isMobile ? "100%" : "80%"}
              height={isMobile ? "100%" : "80%"}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default Hero;
