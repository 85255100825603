/* eslint-disable */
const content = [
  {
    title: "Build ventures",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/build_wkvp1b.svg",
    description: `We utilize our vast array of tech talent and experience as entrepreneurs to deploy resources to identify and co-build innovative tech start-ups.`,
  },
  {
    title: "Launch",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/launch_b8rzhy.svg",
    description: `We utilize our expertise in developing and launching a successful ed-tech business on the continent to grow and develop our portfolio companies.`,
  },
  {
    title: "Funding",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/funding_wiiv6i.svg",
    description: `We leverage our network of angel investors and venture capital funds to raise seed funding to support and develop our portfolio companies.`,
  },
  {
    title: "Co-innovate",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850529/website-assets/logo/co_inno_af6n4h.svg",
    description: `We work closely with corporate partners to build and develop innovative technology solutions that tackle industry-specific problems.`,
  },
  {
    title: "Nurture teams",
    image:
      "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665850247/website-assets/logo/nuture_team_p7ghyg.svg",
    description: `We nurture teams of talented individuals by providing resources, business strategy, and organizational support to help them reach their ground-breaking potential.`,
  },
];

export default content;
