/* eslint-disable */

import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function ReasonWhy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box paddingY={isMobile ? "10px" : 5} sx={{ backgroundColor: "#F6F6F6" }}>
      <Container>
        <Grid
          container
          my={isMobile && 20}
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={4}
        >
          <Grid item textAlign="center">
            <Stack
              direction="column"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <Typography
                lineHeight="40px"
                letterSpacing="-4"
                fontWeight={700}
                color="#101828"
                fontSize="32px"
              >
                Why are we doing this?
              </Typography>
              <Box mt="10px">
                <img
                  src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742042/website-assets/images/Home_Images/yellowDash_czrtun.svg"
                  alt="underline"
                />
              </Box>
            </Stack>
            <Stack
              direction="column"
              alignItems="left"
              textAlign="left"
              justifyContent="left"
            >
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                In 2040, Africa will be the continent with the largest workforce
                in the world. However, current job prospects are insufficient
                for the working-age population, and formal jobs cannot absorb
                the growing working-age population. Africa&apos;s future rests
                largely on the success of her youth, whose enterprises can
                catalyse economic growth and development. With a GDP growth of
                just 2.53%, Nigeria&apos;s economic system cannot provide enough
                jobs for the population, which some estimates say is growing at
                least 2.6% annually. The consequence of this is that the
                unemployment rate in Nigeria rose to 33.3% in December 2020,
                according to a report published by Nigeria&apos;s National
                Bureau of Statistics. This is an increase from 27.1% in the
                second quarter of 2020
              </Typography>
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                Entrepreneurship is a promising approach to transforming &apos;
                job seekers&apos; into &apos;job creators,&apos; hence the
                challenge - How can Africa&apos;s enterprising youths become
                sustainable job creators? At the core of this challenge are
                three key issues: first, translating the high volume of
                entrepreneurial activities on the continent into investable
                job-creating ventures; second, closing the massive skills gaps
                among young African entrepreneurs; and third, building better
                functioning innovation ecosystems to support start-ups. In the
                current Nigerian ecosystem, it is almost impossible to
                successfully transform a desire for entrepreneurship into an
                &apos;investment ready&apos; initiative without the relevant
                ecosystem support.
              </Typography>
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                For these youths, access to credible technical and business
                education with reputable institutions and coaching and mentoring
                to develop their business ideas are their short-term focus.
                Their long-term focus is gaining exposure to funders, investors,
                and other key stakeholders who can support their ultimate dream
                of building investable businesses. Furthermore, youth
                entrepreneurs in silos are isolated from the &apos;real
                world&apos; and necessary market support to enable their venture
                creation journeys. Ecosystem players such as academic
                institutions, industry partners, and investors have the
                knowledge, human and financial assets, able to offer specialised
                technical and market support for young entrepreneurs but lack a
                safe platform to connect with them sustainably. For investors,
                access to portals where credible and validated investment-ready
                start-ups are forged remains a critical short- and long-term
                need.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ReasonWhy;
