/* eslint-disable */
import {
  Box,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function InvestmentStrategy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box paddingY={isMobile ? 0 : 5} sx={{ backgroundColor: "#F6F6F6" }}>
      <Container>
        <Grid
          container
          justifyContent={isMobile ? "center" : "space-between"}
          paddingX={isMobile ? "10px" : "2%"}
          paddingY={isMobile ? 10 : 5}
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          textAlign={isMobile ? "center" : "left"}
          backgroundColor="rgba(27, 138, 237, 0.05)"
          borderRadius={isMobile ? 0 : "8px"}
        >
          <Grid
            item
            display={!isMobile && "flex"}
            justifyContent={!isMobile && "left"}
            order={isMobile ? 2 : 1}
          >
            <img
              src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742915/website-assets/images/ventures-fund/investmentImg_vj1rfb.svg"
              alt="investment"
              width={isMobile ? "100%" : "80%"}
              height={isMobile ? "100%" : "80%"}
            />
          </Grid>
          <Grid
            maxWidth={isMobile ? "100%" : "45%"}
            mt={isMobile && 4}
            item
            order={isMobile ? 1 : 2}
          >
            <Box
              alignItems={isMobile && "center"}
              textAlign={isMobile ? "center" : "left"}
            >
              <Typography
                mb={2}
                lineHeight="40px"
                fontWeight={600}
                color="#101828"
                fontSize={isMobile ? "24px" : "32px"}
              >
                Investment Strategy
              </Typography>
              <img
                src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742042/website-assets/images/Home_Images/yellowDash_czrtun.svg"
                alt="underline"
              />
              <Stack
                spacing="30px"
                mt="20px"
                alignItems={isMobile && "center"}
                mb={isMobile && 10}
              >
                <Typography
                  lineHeight="30px"
                  fontWeight="400"
                  color="#3F3F3F"
                  fontSize={isMobile ? "16px" : "18px"}
                >
                  At Semicolon Ventures, we utilize a thorough investment
                  approach to startup funding, only accepting companies that
                  have scaled through our rigorous selection process and have a
                  focus on a vast addressable market.
                  <br />
                  <br />
                  To de-risk our investments, we focus on companies that build
                  products for a clear customer base and have a means of
                  addressing the market. Our support gears towards reducing the
                  average cost of building a startup, creating lean and
                  efficiently run companies capable of launching and scaling
                  with the same lean ethos.
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default InvestmentStrategy;
