/* eslint-disable */
import { Grid, Typography, useMediaQuery, Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "../ventureHero.css";

function Hero() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box backgroundColor="#F4F4F4" className="venture-hero-bg">
      <Container>
        <Grid
          container
          height="100vh"
          textAlign="center"
          direction={isMobile ? "" : "row"}
          alignItems="center"
          pt={isMobile && 10}
          justifyContent={isMobile ? "center" : "space-between"}
          className="venture-hero-text"
        >
          <Grid
            item
            mt={isMobile ? "5%" : ""}
            mb={isMobile ? "10%" : ""}
            textAlign={isMobile ? "center" : "left"}
            order={isMobile ? 2 : 1}
          >
            <Typography
              fontWeight={isMobile ? 600 : 700}
              fontSize={isMobile ? 32 : 60}
              lineHeight={isMobile ? "" : "78px"}
              color="#1B8AED"
              textAlign={isMobile ? "center" : "left"}
              letterSpacing={isMobile ? "" : "-4px"}
              width={isMobile ? "265px" : "435px"}
            >
              Venture Fund
            </Typography>
            <Typography
              fontSize={isMobile ? "16px" : "20px"}
              mt="20px"
              lineHeight={isMobile ? "" : "30px"}
              fontWeight={400}
              color="#475661"
              textAlign={isMobile ? "center" : "left"}
              maxWidth={isMobile ? "265px" : "435px"}
            >
              Addressing the lack of access to early-stage funding for startups
              in Africa.
            </Typography>
          </Grid>
          <Grid
            mt={isMobile ? "2%" : "8%"}
            display={!isMobile && "flex"}
            justifyContent={!isMobile && "right"}
            item
            order={isMobile ? 1 : 2}
          >
            <img
              src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665851048/website-assets/images/Home_Images/Ellipse_117_dqclhv.png"
              alt="growth plant"
              width={isMobile ? "100%" : "90%"}
              height={isMobile ? "100%" : "90%"}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Hero;
