/* eslint-disable */

import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Box, useMediaQuery, Button } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import content from "./content";
import ActivityCard from "./WhatWeDoCard";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = content;

function SwipeableMobileCarousel() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "320px" }} padding={isMobile ? "10px" : 10}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <ActivityCard
                title={step.title}
                description={step.description}
                image={step.image}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Grid container mt="30px" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                width: "65px",
                height: "65px",
                borderRadius: "100px",
                padding: "20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              {theme.direction === "rtl" ? (
                <ArrowForwardRoundedIcon />
              ) : (
                <ArrowBackRoundedIcon />
              )}
            </Button>
            <Button
              sx={{
                width: "65px",
                height: "65px",
                borderRadius: "100px",
                padding: "20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <ArrowBackRoundedIcon />
              ) : (
                <ArrowForwardRoundedIcon />
              )}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SwipeableMobileCarousel;
