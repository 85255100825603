import { Drawer, Button, Grid, Stack, useMediaQuery, Container, Box, IconButton } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../../assets/logos/logo.svg';
import { primaryColor } from 'util/constant';
import HeaderLink from './HeaderLink';
import { Menu } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function Header() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const page = useLocation().pathname;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box position="fixed" top={0} width="100vw" sx={{ backgroundColor: 'white', zIndex: 10 }} py={1}>
            <Container>
                <Grid container className="header" alignItems="center" justifyContent="space-between" height="60px">
                    <Grid item>
                        <Link to="/">
                            <img src={Logo} alt="semicolon ventures logo" />
                        </Link>
                    </Grid>
                    {isMobile ? (
                        <Grid item>
                            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                                <Menu />
                            </IconButton>
                            <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                                <Grid container backgroundColor="#fff" justifyContent="left">
                                    <Grid item>
                                        <Grid
                                            container
                                            alignItems="center"
                                            minWidth="100vw"
                                            height="100px"
                                            py="auto"
                                            sx={{
                                                px: `${isMobile ? 27 : 100}px`
                                            }}
                                            justifyContent="space-between"
                                            borderBottom="0.5px Solid rgba(211, 211, 211, 0.3)"
                                        >
                                            <Grid item>
                                                <img src={Logo} alt="logo" />
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                                                    <CloseOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item mt={5} mx="20px">
                                        <Stack direction="column" spacing="40px" justifyContent="space-between" alignItems="left">
                                            <HeaderLink
                                                onclick={() => setOpenDrawer(!openDrawer)}
                                                title="Our Impact"
                                                page="our-impact"
                                                selected={page === '/our-impact'}
                                            />
                                            <HeaderLink
                                                onclick={() => setOpenDrawer(!openDrawer)}
                                                title="Venture Fund"
                                                page="venture-fund"
                                                selected={page === '/venture-fund'}
                                            />
                                            <HeaderLink
                                                onclick={() => setOpenDrawer(!openDrawer)}
                                                title="Our Services"
                                                page="our-services"
                                                selected={page === '/our-services'}
                                            />
                                            <HeaderLink
                                                onclick={() => setOpenDrawer(!openDrawer)}
                                                title="Portfolio"
                                                page="portfolio"
                                                selected={page === '/portfolio'}
                                            />
                                            <HeaderLink
                                                onclick={() => setOpenDrawer(!openDrawer)}
                                                title="Nexus"
                                                page="nexus"
                                                selected={page === '/nexus'}
                                            />
                                            <HeaderLink
                                                onclick={() => setOpenDrawer(!openDrawer)}
                                                title="About Us"
                                                page="about"
                                                selected={page === '/about'}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        fullWidth={isMobile && true}
                                        sx={{
                                            backgroundColor: primaryColor,
                                            marginTop: '20vh',
                                            padding: '15px 32px',
                                            mx: '20px',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            // width: '300px',
                                            height: `${isMobile ? '45px' : '48px'}`,
                                            color: '#fff',
                                            borderRadius: '7px',
                                            textTransform: 'none',
                                            '&:hover': { backgroundColor: '#1B8AED' }
                                        }}
                                        disableElevation
                                    >
                                        Get In Touch
                                    </Button>
                                </Grid>
                            </Drawer>
                        </Grid>
                    ) : (
                        <>
                            <Grid item>
                                <Stack direction="row" spacing={4} alignItems="center">
                                    <HeaderLink title="Our Impact" page="our-impact" selected={page === '/our-impact'} />
                                    <HeaderLink title="Venture Fund" page="venture-fund" selected={page === '/venture-fund'} />
                                    <HeaderLink title="Our Services" page="our-services" selected={page === '/our-services'} />
                                    <HeaderLink title="Portfolio" page="portfolio" selected={page === '/portfolio'} />
                                    <HeaderLink title="Nexus" page="nexus" selected={page === '/nexus'} />
                                    <HeaderLink title="About Us" page="about" selected={page === '/about'} />
                                </Stack>
                            </Grid>
                            <Grid item>
                                {/* <Button
                                    variant="contained"
                                    disableRipple
                                    disableElevation
                                    sx={{
                                        height: `${isMobile ? '45px' : '48px'}`,
                                        backgroundColor: primaryColor,
                                        borderRadius: '8px',
                                        textTransform: 'none'
                                    }}
                                >
                                    Get in touch
                                </Button> */}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
        </Box>
    );
}

export default Header;
