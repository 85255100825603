/* eslint-disable */
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Stack, useMediaQuery, Button, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const industrySegments = [
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798536/website-assets/images/portfolios/roadPadi_y3bvif.svg",
    caption: `A smart web and mobile travel platform for transport service
providers and travellers, that allows users book their travel online
to anywhere, with any transporter, enabling efficient movement of
goods and people at any time.`,
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798519/website-assets/images/portfolios/irespond_sddbgd.svg",
    caption: `iRespond is an efficient mental health care platform that provides
personalized therapy through AI-based self services,
e-consultation, Employee Assistance Program as well as advocacy
through campaigns.`,
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798536/website-assets/images/portfolios/mungi_mzk9sd.svg",
    caption: `Mungin is an agricultural data platform that provides
agricultural insights using intelligent data collection and
collation techniques with the aim of improving processes,productivity and contribution to the agricultural ecosystem.`,
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798536/website-assets/images/portfolios/onhova_znfif2.svg",
    caption: `Onhova is focusing on how to enhance the wholesale andretail distribution network through its digital procurement
platform which enables price discovery, inventory visibility
and seamless stock replenishment.`,
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798536/website-assets/images/portfolios/oneplug_rypu9s.svg",
    caption: `Oneplug is building financial integration infrastructure,
implementing channels available through the advent of open
banking to enable interaction and visibility across the financial
services industry.`,
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798537/website-assets/images/portfolios/storybay_doauqf.svg",
    caption: `Tour Bounty is an integrated social technology platform that curates,collates and displays tourism experiences. By beginning in
Africa, Tour Bounty will preserve cultural heritage across the
continent.`,
  },
  {
    icon: "https://res.cloudinary.com/semicolon-ventures/image/upload/v1666798515/website-assets/images/portfolios/goodtime_ianecg.svg",
    caption: `Goodtime is a community curated for users and businesses that
match-makes people based on their recreational and social
interest. Goodtime aims to address the lack of interconnectivity
between users, recreational activities and needs`,
  },
];

function SwipeableMobileCarousel() {
  const contents = industrySegments;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = contents.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid sx={{ maxWidth: "90vw" }} padding={isMobile ? "10px" : 10}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {contents.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Grid
                item
                key={index}
                backgroundColor="#fff"
                minHeight="350px"
                borderRadius="12px"
                p={isMobile ? "5px" : "10px"}
              >
                <Stack alignItems="center" textAlign="center">
                  <img src={step?.icon} alt="" width="100%" />
                  <Typography
                    mt="20px"
                    fontWeight={400}
                    fontSize="16px"
                    lineHeight="21px"
                    color="#101828"
                  >
                    {step?.caption}
                  </Typography>
                </Stack>
              </Grid>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Grid container mt="30px" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center" gap={2}>
            <Button
              // size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                width: "65px",
                height: "65px",
                borderRadius: "100px",
                padding: "20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              {theme.direction === "rtl" ? (
                <ArrowForwardRoundedIcon />
              ) : (
                <ArrowBackRoundedIcon />
              )}
            </Button>
            <Button
              sx={{
                width: "65px",
                height: "65px",
                borderRadius: "100px",
                padding: "20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <ArrowBackRoundedIcon />
              ) : (
                <ArrowForwardRoundedIcon />
              )}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SwipeableMobileCarousel;
