/* eslint-disable */
import {
  Typography,
  Stack,
  useMediaQuery,
  Button,
  Box,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function Hero() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      width="100vw"
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://res.cloudinary.com/semicolon-ventures/image/upload/v1665823270/website-assets/images/OurService/home_xgge1w.svg)`,
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Stack
          textAlign="center"
          alignItems="center"
          height="100vh"
          pt={isMobile && 10}
          justifyContent="center"
        >
          <Typography
            textAlign="center"
            fontWeight="700"
            fontSize={`${isMobile ? 32 : 64}px`}
            letterSpacing="-1%"
            lineHeight="72px"
            color="white"
          >
            Our Services
          </Typography>
          <Typography
            mt={`${isMobile ? 7 : 26}px`}
            textAlign="center"
            fontWeight="400"
            fontSize={`${isMobile ? 18 : 24}px`}
            lineHeight="30px"
            color="white"
            maxWidth={`${isMobile ? 302 : 558}px`}
          >
            We provide a plethora of services that support and ensure our
            founders and partner organizations birth disruptive and scalable
            ideas
          </Typography>
          {isMobile && (
            <Button
              variant="contained"
              fullWidth={isMobile && true}
              sx={{
                backgroundColor: "#1B8AED",
                marginTop: "30vh",
                padding: "15px 32px",
                fontSize: "14px",
                fontWeight: "700",
                width: `${!isMobile && "148px"}`,
                height: `${isMobile ? "45px" : "48px"}`,
                color: "#fff",
                borderRadius: "7px",
                textTransform: "none",
                "&:hover": { backgroundColor: "#1B8AED" },
              }}
              disableElevation
            >
              <Typography
                fontSize="16px"
                color="#FFFFFF"
                lineHeight="24px"
                fontWeight={700}
              >
                Get in touch
              </Typography>
            </Button>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

export default Hero;
