/* eslint-disable */
import {
  Box,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const partners = [
  "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665745904/website-assets/images/Nexus/partner1_dizhvm.svg",
  "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665745902/website-assets/images/Nexus/partner2_xlw1s6.svg",
  "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665745902/website-assets/images/Nexus/partner3_aaemcr.svg",
  "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665745907/website-assets/images/Nexus/partner4_vr6cxy.svg",
  "https://res.cloudinary.com/semicolon-ventures/image/upload/v1665745905/website-assets/images/Nexus/partner5_ppd7ie.svg",
];

function Partners() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid backgroundColor="#F6F6F6" width="100vw">
      <Grid
        container
        my={isMobile && 20}
        paddingY={isMobile ? "10px" : 10}
        // justifyContent="center"
        // alignItems="left"
        // textAlign="left"
        gap={4}
      >
        <Grid item textAlign="left">
          <Typography
            lineHeight="40px"
            letterSpacing="-4"
            fontWeight={700}
            paddingLeft="120px"
            color="#101828"
            fontSize="32px"
          >
            Partners
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={isMobile ? "10px" : 5}
            width="100vw"
            justifyContent="center"
            alignItems="top"
          >
            {partners.map((value, index) => (
              <Grid key={index} item lg={2} xl={3} md={4} xs={12} sm={12}>
                <img src={value} alt="partner" width="100%" />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Partners;
