/* eslint-disable */

import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function NexusProject() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box paddingY={isMobile ? "10px" : 5} sx={{ backgroundColor: "#F6F6F6" }}>
      <Container>
        <Grid
          container
          my={isMobile && 10}
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={4}
        >
          <Grid item textAlign="center">
            <Typography
              mb={isMobile ? "20px" : "40px"}
              lineHeight="44px"
              letterSpacing="-4"
              fontWeight={700}
              color="#101828"
              fontSize="32px"
            >
              A transformative partnership from Henley Business School, Lagos
              Business School and Semicolon Africa
            </Typography>
            <Stack
              direction="column"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <Typography
                lineHeight="40px"
                letterSpacing="-4"
                fontWeight={700}
                color="#101828"
                fontSize="32px"
              >
                What is the Nexus Project?
              </Typography>
              <Box mt="10px">
                <img
                  src="https://res.cloudinary.com/semicolon-ventures/image/upload/v1665742042/website-assets/images/Home_Images/yellowDash_czrtun.svg"
                  alt="underline"
                />
              </Box>
              <Typography
                my="20px"
                width="90%"
                lineHeight="30px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "14px" : "20px"}
              >
                The Nexus is a virtual platform that de-isolates young
                entrepreneurs and connects them to an ecosystem of knowledge
                intervention and technical support services. It is designed to
                improve the chances of building sustainable businesses, thereby
                improving the conversion rate of job seekers into job creators
                and reducing the failure rate of start-ups in the context.
              </Typography>
            </Stack>
          </Grid>
          <Grid item textAlign="left">
            <Typography
              my="20px"
              width="90%"
              lineHeight={isMobile ? "18px" : "24px"}
              fontWeight={500}
              color="#000"
              fontSize={isMobile ? "18px" : "24px"}
            >
              The Nexus platform will have the following features:
            </Typography>
            <Stack
              direction="column"
              alignItems="left"
              textAlign="left"
              justifyContent="left"
            >
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                1. The Investment Readiness Map (IRM) – a personalized mapping
                of enrolled entrepreneurs&apos; learner journey. It is a
                market-driven narrative articulating the entrepreneur&apos;s
                journey from &apos;unlocked&apos; assets to investable and
                sustainable ventures. The IRM is expected to embed an Investment
                Readiness Scorecard capable of identifying investor-proof
                knowledge gaps and learning needs of enrolled entrepreneurs.
                This will then inform the knowledge and industry interventions
                to be delivered across the project timeframe.
              </Typography>
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                2. (A) Academic institutions&apos; content delivery capability
                to deliver knowledge intervention throughout the project based
                on the identified knowledge gaps learning needs.
              </Typography>
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                2. (B) Personal Development and Wellbeing component that
                addresses the individual development and wellness of the
                founders throughout their journey.
              </Typography>
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                3. Comprehensive coaching and mentoring programme aimed at
                connecting the target youth to technical knowledge and expertise
              </Typography>
              <Typography
                my="20px"
                width="100%"
                lineHeight="24px"
                fontWeight={400}
                color="#000"
                fontSize={isMobile ? "16px" : "20px"}
              >
                4. An investment hub designed to showcase investors for
                investment-ready entrepreneurs to secure investments for their
                viable businesses or products
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default NexusProject;
