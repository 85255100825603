/* eslint-disable */
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import * as Yup from 'yup';
// import * as emailjs from 'emailjs-com';
// import { useFormik } from 'formik';
import { useState } from "react";

function GetInTouch() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [buttonState, setButtonState] = useState("Send Message");

  // const formik = useFormik({
  //     initialValues: {
  //         from_name: '',
  //         to_name: process.env.REACT_APP_ADMIN_EMAIL,
  //         subject: '',
  //         reply_to: '',
  //         message: ''
  //     },
  //     validationSchema: Yup.object({
  //         from_name: Yup.string()
  //             .required('* Name field is required'),
  //         subject: Yup.string()
  //             .required('* Subject field is required'),
  //         reply_to: Yup.string().email('Invalid email address')
  //             .required('* Email field is required'),
  //         message: Yup.string().required('* Message field is required')
  //     }),
  //     onSubmit: (values) => {
  //         try {
  //             emailjs.send(process.env.REACT_APP_FORMIK_SERVICE_ID, process.env.REACT_APP_FORMIK_TEMPLATE_ID, values, process.env.REACT_APP_FORMIK_USER_ID)
  //                 .then(() => {
  //                     sentMessage.classList.add('success');
  //                     sentMessage.innerHTML = CONTACT_ERROR.success;
  //                     setButtonState('Send Email');
  //                     setSubmitting(false);
  //                     resetForm();
  //                 });
  //         }
  //         catch {
  //             sentMessage.classList.add('error');
  //             sentMessage.innerHTML = CONTACT_ERROR.error;
  //             setButtonState('Send Email');
  //             setSubmitting(false);
  //         }
  //     }

  // });

  return (
    <Box
      sx={{
        backgroundImage: `url(https://res.cloudinary.com/semicolon-ventures/image/upload/v1665743142/website-assets/images/ventures-fund/getInTouchBg_twub0t.svg)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Grid
          container
          justifyContent={isMobile ? "center" : "space-between"}
          paddingY={isMobile ? 10 : 20}
          alignItems="center"
          // textAlign={isMobile ? 'center' : 'left'}
        >
          <Grid mt={isMobile && 4} item lg={6} xl={6} md={6} xs={12} sm={12}>
            <Box alignItems={isMobile && "center"}>
              <Typography
                mb={2}
                lineHeight="40px"
                fontWeight={600}
                color="#fff"
                fontSize={isMobile ? "24px" : "32px"}
              >
                Get In Touch
              </Typography>
              <Typography
                fontSize={isMobile ? "16px" : "20px"}
                fontWeight={400}
                color="#667085"
              >
                We would love to hear from you
              </Typography>
            </Box>
          </Grid>
          <Grid mt={isMobile && 4} item lg={6} xl={6} md={6} xs={12} sm={12}>
            <form>
              <Grid
                container
                alignItems="center"
                spacing={2}
                justifyContent="center"
              >
                <Grid item lg={6} xl={6} md={6} xs={12} sm={12}>
                  <Typography
                    mb="6px"
                    fontSize="16px"
                    fontWeight="600"
                    color="#fff"
                  >
                    First name
                  </Typography>
                  <TextField
                    id="from_name"
                    name="from_name"
                    type="text"
                    autoComplete="off"
                    // onChange={formik.handleChange}
                    // value={formik.values.from_name}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    fullWidth
                    placeholder="First name"
                  />
                </Grid>
                <Grid item lg={6} xl={6} md={6} xs={12} sm={12}>
                  <Typography
                    mb="6px"
                    fontSize="16px"
                    fontWeight="600"
                    color="#fff"
                  >
                    Last name
                  </Typography>
                  <TextField
                    id="from_name"
                    name="from_name"
                    type="text"
                    autoComplete="off"
                    // onChange={formik.handleChange}
                    // value={formik.values.from_name}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    fullWidth
                    placeholder="Last name"
                  />
                </Grid>
                <Grid item lg={12} xl={12} xs={12} sm={12}>
                  <Typography
                    mb="6px"
                    fontSize="16px"
                    fontWeight="600"
                    color="#fff"
                  >
                    Email Address
                  </Typography>
                  <TextField
                    id="reply_to"
                    type="email"
                    name="reply_to"
                    autoComplete="off"
                    // onChange={formik.handleChange}
                    // value={formik.values.reply_to}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    fullWidth
                    placeholder="you@company.com"
                  />
                </Grid>
                <Grid item lg={12} xl={12} xs={12} sm={12}>
                  <Typography
                    mb="6px"
                    fontSize="16px"
                    fontWeight="600"
                    color="#fff"
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    fullWidth
                    placeholder="+234-(0)-817-000-0000"
                  />
                </Grid>
                <Grid item lg={12} xl={12} xs={12} sm={12}>
                  <Typography
                    mb="6px"
                    fontSize="16px"
                    fontWeight="600"
                    color="#fff"
                  >
                    Message
                  </Typography>
                  <TextField
                    id="message"
                    name="message"
                    // autoComplete="off" onChange={formik.handleChange}
                    // value={formik.values.message}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    fullWidth
                    multiline
                    rows={5}
                    placeholder="message"
                  />
                </Grid>
                <Grid item lg={12} xl={12} xs={12} sm={12}>
                  <Button
                    sx={{
                      backgroundColor: "#1B8AED",
                      borderRadius: "8px",
                      width: "100%",
                      height: `${isMobile ? "45px" : "48px"}`,
                      fontSize: "16px",
                      padding: "12px 32px",
                      textTransform: "none",
                      fontWeight: "600",
                    }}
                    variant="contained"
                  >
                    {buttonState}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default GetInTouch;
